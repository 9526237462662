import React from "react"
import loadable from "@loadable/component"
import { makeStyles } from "@material-ui/core/styles"
import "../../css/typography-muli.css"
const ChatComponent = loadable(() =>
  import("../../components/prod/custom-page")
)

const useStyles = makeStyles({
  circle: {
    position: "absolute",
    height: "385px",
    width: "489px",
    bottom: 0,
    right: "2%",
  },
  dots: {
    position: "absolute",
    left: "10%",
    top: "100px",
  },
})

function Chat() {
  // doing this loadable import so createStore works
  // https://stackoverflow.com/questions/65398447/webpackerror-typeerror-object-is-not-a-function-gatsbyjs-build-error-bot
  const classes = useStyles()

  return (
    <ChatComponent
      name="Gewerbeanmeldung Assistent"
      pageTitle="Convaise - Gewerbeanmeldung"
      // homepage="#"
      // token="3jwgou3DF10.A35SD0t0jPu-rCUgveaj4VUNYjOyV_Afesxi5A9J02c"
      token="-BeR0fBw97I.tXA01fMOkW4qALJZ9KvPzRUM4Y7Smfi0xrYi2xus8-s" // bots-prod-01
      // token="AhaaisD-ce0.7csNC_FrUbij1tjiy_bqmh-hON-1H7nc0SA-L5RG4jE" // bot-process-dev-01
      botId="3bo25pskks8hw74"
      avatar={false}
      avatarInitials={false}
      logo="https://static.convaise.com/convaise-logo.svg"
      domain="https://europe.directline.botframework.com/v3/directline"
      styleOptions={{
        primaryFont: "'Noto Sans', 'sans-serif'",
        bubbleFromUserTextColor: "white",
        bubbleTextColor: "#293754",
        bubbleFromUserBackground: "#394d75",
        botAvatarBackgroundColor: "#fff",
        // primaryFont:
        //   '"Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      }}
      // styleCustomizations={{
      //   "--button-blue": "#551285",
      //   "--button-blue-light": "#77419D",
      //   "--button-orange": "rgba(255, 166, 0, 1)",
      //   "--button-orange-light": "rgb(255, 182, 46)",
      // }}
      backgroundColor="#F0F4F5"
      customs={[
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-circle.svg"
          className={classes.circle}
          role="presentation"
          alt=""
          key="customization-circle"
        />,
        <img
          src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          data-src="https://static.convaise.com/demos/gegenbauer/gegenbauer-dots.svg"
          className={classes.dots}
          key="customization-dots"
          role="presentation"
          alt=""
        />,
      ]}
      // css="https://static.convaise.com/webchat/gegenbauer/convaise-assistant-gegenbauer-iframe-min.css"
    />
  )
}

export default Chat
